import { ChangeEvent } from 'react';

import { endOfDay, isBefore } from 'date-fns';
import { UseFormSetValue } from 'react-hook-form';

import {
  DeclineShareExchangeFieldNames,
  FormItem,
  Option,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
  TableColumns,
} from 'interfaces';
import { StartShareExchangeClosingDatesTooltip } from 'page-components';
import { Input, SelectFormInput } from 'shared-components';
import NumberInput from 'shared-components/NumberInput';
import { checkIsClosingDatesExistsAndNotExpired } from 'utils/deals';
import { calculateNoOfExchangesShares } from 'utils/investments';

import { getTitleWithReplacedYourWord } from '../utils';
import { SHARE_EXCHANGE_STAGES } from './deals';
import { INTEGER_PATTERN, REQUIRED_MESSAGE } from './global';
import { ColoredLabelVariants, FieldTypes } from './shared';
import { FieldTooltipVariants } from './shared/input';

export const SHARE_EXCHANGE_REQUESTS_PAGE_TITLE = 'Share Exchange Requests';

export const SHARE_EXCHANGE_REQUESTS_PAGE_DESCRIPTION = 'View all share exchanges on the planD platform';

export const SHARE_EXCHANGE_COMPLETE_MESSAGE = 'The share exchange was successfully completed';

export const SHARE_EXCHANGES_COMPLETE_MESSAGE = 'The share exchanges were successfully completed';

export const SHARE_EXCHANGE_STOP_MESSAGE = 'The share exchange was successfully stopped';

export const SHARE_EXCHANGES_STOP_MESSAGE = 'The share exchanges were successfully stopped';

export const SHARE_EXCHANGE_STOP_FORM_LABEL =
  'Please let us know in a few words why you would like to stop the share exchange - your feedback is greatly appreciated';

export const SHARE_EXCHANGE_OLD_STRUCTURE_PROGRESS_BAR_MESSAGE =
  'The progress bar can’t be shown because the deal has an old structure.';

export const SHARE_EXCHANGE_CREATE_MESSAGE = 'Share exchange was successfully created';

export const SHARE_EXCHANGES_CREATE_MESSAGE = 'Share exchanges were successfully created';

export const SHARE_EXCHANGE_REQUESTS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions', className: 'w-44' },
  {
    id: 'companyName',
    title: 'Company Name',
    sortable: true,
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'created_at',
    title: 'Date Created',
    sortable: true,
  },
  {
    id: 'investor',
    title: 'Investor',
  },
  {
    id: 'investor_phone',
    title: 'Investor Phone',
  },
  {
    id: 'investor_email',
    title: 'Investor Email',
  },
];

export enum ShareExchangeStatuses {
  INITIATED = 'initiated',
  CANCELED = 'canceled',
  DECLINED = 'declined',
  UNDER_REVIEW = 'under_review',
  IN_PROGRESS = 'in_progress',
  APPROVED = 'approved',
  FINISHED = 'finished',
  DONE = 'done',
  ARCHIVED = 'archived',
}

export enum StartShareExchangeFieldNames {
  CLASS_OF_SHARES = 'classOfShares',
  NO_OF_SHARES = 'noOfShares',
  COMPANY_PRICE = 'companyPrice',
  DAXIA_PRICE = 'fundSharePrice',
  NO_OF_EXCHANGED_SHARES = 'noExchangedShares',
  CLOSING_DATE = 'closingDate',
  ORIGINAL_INVESTMENT_PRICE = 'originalInvestmentPrice',
}

export const CANCELABLE_SHARE_EXCHANGE_STATUSES = [
  ShareExchangeStatuses.IN_PROGRESS,
  ShareExchangeStatuses.INITIATED,
  ShareExchangeStatuses.UNDER_REVIEW,
];

export const shareExchangeStatusesText: Record<ShareExchangeStatuses, string> = {
  [ShareExchangeStatuses.INITIATED]: 'Initiated',
  [ShareExchangeStatuses.CANCELED]: 'Canceled',
  [ShareExchangeStatuses.DECLINED]: 'Declined',
  [ShareExchangeStatuses.UNDER_REVIEW]: 'Admin review',
  [ShareExchangeStatuses.IN_PROGRESS]: 'In progress',
  [ShareExchangeStatuses.APPROVED]: 'Approved',
  [ShareExchangeStatuses.DONE]: 'Done',
  [ShareExchangeStatuses.FINISHED]: 'Finished',
  [ShareExchangeStatuses.ARCHIVED]: 'Archived',
};

export const shareExchangeStatusesColoredLabel: {
  [key in ShareExchangeStatuses]: { children: string; variant: ColoredLabelVariants };
} = {
  [ShareExchangeStatuses.INITIATED]: { children: 'Initiated', variant: ColoredLabelVariants.PENDING },
  [ShareExchangeStatuses.CANCELED]: { children: 'Canceled', variant: ColoredLabelVariants.ERROR },
  [ShareExchangeStatuses.DECLINED]: { children: 'Declined', variant: ColoredLabelVariants.ERROR },
  [ShareExchangeStatuses.UNDER_REVIEW]: { children: 'Admin review', variant: ColoredLabelVariants.PENDING },
  [ShareExchangeStatuses.IN_PROGRESS]: { children: 'In progress', variant: ColoredLabelVariants.PENDING },
  [ShareExchangeStatuses.APPROVED]: { children: 'Approved', variant: ColoredLabelVariants.SUCCESS },
  [ShareExchangeStatuses.DONE]: { children: 'Done', variant: ColoredLabelVariants.SUCCESS },
  [ShareExchangeStatuses.FINISHED]: { children: 'Finished', variant: ColoredLabelVariants.SUCCESS },
  [ShareExchangeStatuses.ARCHIVED]: { children: 'Archived', variant: ColoredLabelVariants.PENDING },
};

export enum SHARE_EXCHANGE_REVIEW_SECTION_TITLES {
  STATUS = 'Share Exchange:',
  IN_REQUEST = 'Shares In Request',
  VIEW_ANSWERS = 'Share Exchange Qualification Questions',
}

export const SHARE_EXCHANGE_REVIEW_INVESTMENTS_COLUMNS: TableColumns[] = [
  {
    id: 'classOfShares',
    title: 'Class of Shares',
  },
  {
    id: 'totalShares',
    title: 'No. Shares',
  },
  {
    id: 'pricePerShare',
    title: 'Share Price',
  },
  {
    id: 'investmentDate',
    title: 'Investment Date',
  },
  {
    id: 'status',
    title: 'Status',
  },
];

export const CANCEL_SHARE_EXCHANGE_MODAL_TITLE = 'Confirm Selection';

export const CANCEL_SHARE_EXCHANGE_MODAL_DESCRIPTION = 'Are you sure you want to cancel your share exchange request?';

export const DECLINE_SHARE_EXCHANGE_MODAL_TITLE = 'Confirm Selection';

export const getDeclineShareExchangeModalFields = (label?: string): FormItem[] => [
  {
    name: DeclineShareExchangeFieldNames.REASON,
    label: label || 'Reasoning',
    placeholder: 'Text',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: {
        value: 50,
        message: 'Max 50 chars',
      },
    },
    className: 'mb-0',
  },
];

export const START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS = (
  index: number,
  setValue: UseFormSetValue<StartShareExchangesInvestmentsFormValues>,
  watchValues: StartShareExchangeWatchValues,
  classOfSharesOptions: Option[],
  closingDatesOptions: Option[],
  handleSetCompanyPrice: VoidFunction,
  isTypeEntity: boolean,
  entityName: string,
): FormItem[] => {
  const shareExchangesPrefix: `exchanges.${number}` = `exchanges.${index}`;

  const { noOfShares, companyPrice, fundSharePrice } = watchValues;

  return [
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}`,
      label: 'Class of shares',
      placeholder: 'Class of shares',
      type: FieldTypes.SELECT,
      component: SelectFormInput,
      required: true,
      options: classOfSharesOptions,
      validation: { required: REQUIRED_MESSAGE },
      className: 'col-span-full md:col-span-4 mb-0',
      onChange: (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(
          `${shareExchangesPrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}`,
          e.target.value as unknown as Option,
        );
        handleSetCompanyPrice();
      },
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}`,
      label: 'No. shares',
      placeholder: 'No. shares',
      tooltipVariant: FieldTooltipVariants.WITH_ICON,
      tooltipContent: 'Please enter this figure if known. If unknown, you can amend / add the number at a later time',
      type: FieldTypes.NUMBER,
      component: NumberInput,
      className: 'col-span-full md:col-span-4 mb-0',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(INTEGER_PATTERN, '');
        setValue(`${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}`, value);
        setValue(
          `${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}`,
          calculateNoOfExchangesShares({ noOfShares: value, companyPrice, fundSharePrice }),
        );
      },
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.ORIGINAL_INVESTMENT_PRICE}`,
      label: 'Total initial investment value',
      placeholder: 'Total initial investment value',
      type: FieldTypes.NUMBER,
      component: NumberInput,
      className: 'col-span-full md:col-span-4 mb-0',
      fractionLength: 50,
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.CLOSING_DATE}`,
      label: 'Closing date',
      placeholder: 'Closing date',
      type: FieldTypes.SELECT,
      component: SelectFormInput,
      options: closingDatesOptions,
      required: true,
      className: 'col-span-full md:col-span-6 2lg:col-span-3 mb-0',
      tooltipVariant: FieldTooltipVariants.WITH_ICON,
      tooltipContent: !checkIsClosingDatesExistsAndNotExpired(closingDatesOptions)
        ? undefined
        : StartShareExchangeClosingDatesTooltip,
      validation: {
        required: REQUIRED_MESSAGE,
        validate: (option: Option) => {
          const isDateBefore = isBefore(endOfDay(new Date(option.value)), endOfDay(new Date()));

          if (isDateBefore) {
            return "Date can't be in the past";
          }
          return true;
        },
      },
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}`,
      label: 'Price per share',
      placeholder: 'Price per share',
      type: FieldTypes.TEXT,
      tooltipVariant: FieldTooltipVariants.WITH_ICON,
      tooltipContent: getTitleWithReplacedYourWord(
        'This is the offer price per share that Daxia are willing to pay',
        entityName,
        isTypeEntity,
      ),
      component: Input,
      disabled: true,
      validation: { required: REQUIRED_MESSAGE },
      className: 'col-span-full md:col-span-6 2lg:col-span-3 mb-0',
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}`,
      label: 'Daxia price per share',
      placeholder: 'Daxia price per share',
      type: FieldTypes.TEXT,
      component: Input,
      required: true,
      disabled: true,
      validation: { required: REQUIRED_MESSAGE },
      className: 'col-span-full md:col-span-6 2lg:col-span-3 mb-0',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(INTEGER_PATTERN, '');
        setValue(`${shareExchangesPrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}`, value);
        setValue(
          `${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}`,
          calculateNoOfExchangesShares({ fundSharePrice: value, noOfShares, companyPrice }),
        );
      },
    },
    {
      name: `${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}`,
      label: 'Daxia shares to be received',
      placeholder: 'Daxia shares to be received',
      type: FieldTypes.NUMBER,
      tooltipVariant: FieldTooltipVariants.WITH_ICON,
      tooltipContent: getTitleWithReplacedYourWord(
        'This is the number of shares in Daxia you will receive in return for your share',
        entityName,
        isTypeEntity,
      ),
      component: NumberInput,
      required: true,
      disabled: true,
      validation: { required: REQUIRED_MESSAGE },
      className: 'col-span-full md:col-span-6 2lg:col-span-3 mb-0',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(INTEGER_PATTERN, '');
        setValue(`${shareExchangesPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}`, value);
      },
    },
  ];
};

export const SHARE_EXCHANGE_NOT_AVAILABLE_FOR_EMAIL = [SHARE_EXCHANGE_STAGES.COMPLETED];

export const REVIEW_SHARE_EXCHANGE_QUALIFICATION_QUESTIONS_ANSWERS_MODAL_DESCRIPTION =
  'Review the submitted share exchange qualification questions';

export enum WELCOME_INVESTOR_SHARE_EXCHANGE_LIST_ACTION {
  LIST = 'list',
}

export enum WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES {
  ADD_INVESTMENTS = 'addInvestments',
  INITIATE_EXCHANGE = 'initiateExchange',
  CONTINUE_EXCHANGE = 'continueExchange',
}
