import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { INVESTORS_TABLE_COLUMNS, INVESTMENTS_PAGE_PLACEHOLDER } from 'constants/investors';
import { ROUTES } from 'constants/routes';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { InvestorActionsParamsType } from 'interfaces';
import { getInvestors } from 'modules/investors/action';
import { selectInvestorsData } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import InvestorsTableRow from './InvestorsTableRow';

const INVESTORS_PER_PAGE = 7;

const InvestorsTable = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { list: investors, isLoading, ...investorsPaginationData } = useAppSelector(selectInvestorsData);

  const getInvestorActions = ({ id }: InvestorActionsParamsType) => {
    return [
      {
        title: 'Investor profile',
        id: 'profile',
        action: () => {
          navigate(`${ROUTES.admin}${ROUTES.investor}`.replace(':id', id.toString()));
        },
      },
    ];
  };

  useEffect(() => {
    dispatch(
      getInvestors({ page, per_page: INVESTORS_PER_PAGE, query, order: sorting.order, sort: sorting.column, filter }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, filter]);

  return (
    <div className='flex flex-col'>
      <SearchForm
        className='w-full sm:w-7/12 flex-col xs:flex-row gap-4 xs:gap-0 items-center mb-12'
        handleFilter={handleFilter}
        placeholder={INVESTMENTS_PAGE_PLACEHOLDER}
      />

      <Table onUpdateSorting={updateSorting} columns={INVESTORS_TABLE_COLUMNS}>
        {isLoading ? (
          <TableSkeleton rowsNumber={INVESTORS_PER_PAGE} columnsNumber={INVESTORS_TABLE_COLUMNS.length} />
        ) : (
          investors &&
          investors.map((investor) => (
            <InvestorsTableRow getActions={getInvestorActions} key={investor.id} investor={investor} />
          ))
        )}
      </Table>

      <Pagination currentPage={page} updatePage={setPage} {...investorsPaginationData} />
    </div>
  );
};

export default InvestorsTable;
