export enum LocalStorageKeys {
  COMPANY_NAME = 'companyName',
  AUTH_ACCESS_TOKEN = 'authAccessToken',
  ACCESS_TOKEN = 'accessToken',
  ACCESS_EXPIRED_TIME = 'accessTokenExpired',
  IS_FIRST_SIGN_IN = 'isFirstSignIn',
  USER_TYPE = 'userType',
  SIGN_UP_FLOW_PARAMS = 'signUpFlowParams',
  HIDE_INVESTOR_WELCOME_MODAL = 'hideInvestorWelcomeModal',
  HIDE_INVESTOR_WELCOME_MODAL_USERS_IDS = 'hideInvestorWelcomeModalUsersIds',
  DEALS_STAGES_LABELS = 'dealsStagesLabels',
  HIDE_DEALS_FUND_INFO_MODAL_IDS = 'hideDealsFundInfoModalIds',
}

export const PLAND_SUPPORT_EMAIL = 'support@pland.co';

export const PLAND_LOCAL_URL = 'localhost:3000';

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const PHONE_PATTERN = /^\+44\d{10}$/;

export const WEBSITE_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const PASSWORD_PATTERN = /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z\d\s]).*$/;

export const NUMBERS_PATTERN = /^\d*$/;

export const LINKED_IN_PATTERN = /(https:\/\/www\.linkedin\.com\/)(in|company)\/([^?]+)/;

export const NUMBER_AND_LETTERS_PATTERN = /^[A-Za-z0-9 ]*$/;

export const LETTERS_PATTERN = /^[a-zA-Z]+$/;

export const NUMBER_DOUBLE_PATTERN = /^-?[0-9]+([.][0-9]+)?$/;

export const POSITIVE_NUMBER_FLOAT_PATTERN = /^(0|[1-9]\d*)(\.\d+)?$/;

export const DECIMAL_NUMBER_PATTERN = /^(?!0(?:\.0{1,2})?$)\d+(\.\d{1,50})?$/;

export const EXCEPT_FLOAT_NUMBERS_PATTERN = /[^0-9.]/g;

export const HEX_PATTERN = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please, try again';

export const REQUIRED_MESSAGE = 'Missing Required Data';

export const ENTER_NUMBER_MESSAGE = 'Please enter a number';

export const ENTER_INTEGER_NUMBER_MESSAGE = 'Please enter an integer number';

export const ENTER_DECIMAL_NUMBER_MESSAGE = 'Please enter the price';

export const INCORRECT_PHONE_NUMBER_MESSAGE = 'Incorrect phone number';

export const INTEGER_PATTERN = /\D/g;

export const INTEGER_WITH_DOTS_PATTERN = '/[^\\d.]/g';

export const LOCALE_STRING_FORMAT = 'en-US';

export const META_INITIAL_STATE = {
  totalPages: 1,
  total: 0,
};

export const EMAIL_FIELD_VALIDATION = {
  required: REQUIRED_MESSAGE,
  pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
};
