import { FC } from 'react';

import { RegisterOptions } from 'react-hook-form';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { CompanyNatureOfBusiness, MetaPage, MetaState, UserData } from 'interfaces';
import { Props as RadioInputSetProps } from 'shared-components/radio-input-set/RadioInputSet';
import { Props as TextAreaProps } from 'shared-components/text-area/TextArea';

import { Fund } from './funds.interfaces';
import { StringOrNull } from './global.interfaces';
import { Option, RadioOption } from './shared.interfaces';
import { SignatureData } from './signatures.interfaces';

export enum COMPANY_IAC_STATUSES {
  STOPPED = 'stopped',
  STARTED = 'started',
  REVIEWING_BY_FMUSERS = 'reviewing_by_fmusers',
  APPROVED_BY_FMUSERS = 'approved_by_fmusers',
  DECLINED_BY_FMUSERS = 'declined_by_fmusers',
  CONFIRMED_BY_ADMIN = 'confirmed_by_admin',
  ARCHIVED = 'archived',
}

export interface CompanyIacFundManager {
  id: number;
  name: string;
}

export interface CompanyIac {
  id: number;
  isResubmitted: boolean;
  status: COMPANY_IAC_STATUSES;
  fundName: string;
  fundManager: CompanyIacFundManager;
  companyName: string;
  companyNumber: string;
  incorporationDate: string;
  website: StringOrNull;
  businessSector: string;
  stageOfDevelopment: StringOrNull;
  approvalForSecondarySale: StringOrNull;
  notes: StringOrNull;
  timing: StringOrNull;
  numberOfShareholders: StringOrNull;
  proposedPrice: StringOrNull;
  otherDetails: StringOrNull;
  fullyDilutedShareCapital: CompanyIacOverviewShare[];
  companySharePrices: CompanyIacSharePrice[] | null;
  closingDates: CompanyIacClosingDates[];
  sharesToPurchase: CompanyIacOverviewPurchaseAtCompletion;
  overviewCustomInputs: CompanyIacCustomField[];
  companyOverview: StringOrNull;
  backgroundInvestmentProposal: StringOrNull;
  finansialShareCapital: StringOrNull;
  valuationMetrics: StringOrNull;
  summaryCustomInputs: CompanyIacCustomField[];
  marketOpportunity: string;
  competition: string;
  partnershipOpportunity: string;
  IPPosition: StringOrNull;
  managementTeam: StringOrNull;
  cashRunwayInNoOfMonth: StringOrNull;
  milestones: StringOrNull;
  nextInvestmentPlans: StringOrNull;
  externalsUsedForDiligence: StringOrNull;
  externalsUsedForDiligenceNotes: StringOrNull;
  DDRedFlags: StringOrNull;
  DDRedFlagsNotes: StringOrNull;
  regulatoryChecks: StringOrNull;
  regulatoryChecksNotes: StringOrNull;
  otherMatters: StringOrNull;
  otherMattersNotes: StringOrNull;
  conflictsOfInterest: StringOrNull;
  conflictsOfInterestNotes: StringOrNull;
  businessReviewCustomInputs: CompanyIacCustomField[];
  natureOfBusiness: CompanyNatureOfBusiness[];
  hasDealParameter: boolean;
  lastCreatedAt: string | null;
  signature?: SignatureData;
  companyId: number;
}

export interface CompanyIacOverviewShare {
  classOfShares: string;
  noOfSharesShareCapital: string;
  checked: boolean;
}

export interface CompanyIacClosingDates {
  id?: string; // It has ID provided by useFieldArray hook
  duration: string;
  closingDate: string;
}

export interface CompanyIacSharePrice {
  classOfShares: string;
  denomination: string;
  sharePrice: string;
}

export interface CompanyIacOverviewPurchaseAtCompletion {
  percent: string;
  noOfSharesPurchaseAtCompletion: string;
  shareClass: string;
}

export type CompanyIacSummaryFormState = Pick<
  CompanyIac,
  'companyOverview' | 'backgroundInvestmentProposal' | 'finansialShareCapital' | 'valuationMetrics'
>;

export interface CompanyIacCustomField {
  id: number;
  name: string;
  label?: string | undefined;
  value: string;
}

export type CompanyIacCustomFieldFormValues = Record<string, string>;

export type FielsIacActionsParamsType = Pick<CompanyIacCustomField, 'label'>;

export type CompanyIacCustomFieldInput = Omit<CompanyIacCustomField, 'id' | 'name'> & {
  blockName: InvestmentProposalBlockNames;
};

export type CompanyIacOverviewFormValues = Pick<
  CompanyIac,
  | 'id'
  | 'companyName'
  | 'companyNumber'
  | 'businessSector'
  | 'fullyDilutedShareCapital'
  | 'proposedPrice'
  | 'notes'
  | 'incorporationDate'
  | 'timing'
  | 'numberOfShareholders'
  | 'companySharePrices'
  | 'closingDates'
>;

export type UpdateCompanyIacPayload = {
  companyId: string;
  id: number;
  data: Partial<CompanyIac>;
  shouldUpdateStore?: boolean;
};

export interface CompanyIacOverviewFormState extends CompanyIacOverviewFormValues {
  sharesToPurchase: CompanyIacOverviewPurchaseAtCompletion;
  approvalForSecondarySale: Option;
  stageOfDevelopment: Option;
}

export type CompanyIacResponse = { data: CompanyIac };

export interface CompanyIacState {
  companyIac: CompanyIac;
  customFields: { [key in InvestmentProposalBlockNames]: CompanyIacCustomField[] };
  editingSections: InvestmentProposalBlockNames[];
  customFieldsInCreateMode: { [key in InvestmentProposalBlockNames]: boolean };
  funds: Fund[];
  deal: CompanyIacDeal;
}

export interface SetIsCustomFieldsInCreateModePayload {
  blockName: InvestmentProposalBlockNames;
  isCreateMode: boolean;
}

export type CompanyIacBusinessReviewFormValues = Pick<
  CompanyIac,
  | 'marketOpportunity'
  | 'partnershipOpportunity'
  | 'IPPosition'
  | 'cashRunwayInNoOfMonth'
  | 'managementTeam'
  | 'milestones'
  | 'nextInvestmentPlans'
  | 'externalsUsedForDiligence'
  | 'DDRedFlags'
  | 'DDRedFlagsNotes'
  | 'regulatoryChecks'
  | 'regulatoryChecksNotes'
  | 'otherMatters'
  | 'otherMattersNotes'
  | 'conflictsOfInterest'
  | 'conflictsOfInterestNotes'
  | 'competition'
> & { signature: string };

export interface CompanyIacRadioInputSetField extends Partial<RadioInputSetProps> {
  validation?: RegisterOptions;
  radioOptions: RadioOption[];
  radioLegend: string | FC;
  name: string;
}

export interface CompanyIacTextAreaNotesField extends Partial<TextAreaProps> {
  validation?: RegisterOptions;
  name?: string;
}

export interface CompanyIacChooseFundFormState {
  fund: Option;
}

export interface CreateCompanyIacPayload {
  companyId: number;
  fundId: number;
}

export interface AddNewFmUser {
  firstName: string;
  lastName: string;
  email: string;
  addAnother: boolean;
}

export interface CompanyIacInvitePayload {
  id: number;
  fmUsers: UserData[];
  fundManagerId: number;
  companyId?: string;
}

export enum CompanyIacDeclineFieldNames {
  REASON = 'reason',
}

export interface CompanyIacDeclineFormState {
  [CompanyIacDeclineFieldNames.REASON]: string;
}

export interface CompanyIacDeclinePayload {
  id: number;
  reason: string;
}

export enum CompanyIacStopFieldNames {
  REASON_SELECT = 'reason',
  REASON_TEXT = 'reasonText',
}

export interface CompanyIacStopFormState {
  [CompanyIacStopFieldNames.REASON_SELECT]: Option | null;
  [CompanyIacStopFieldNames.REASON_TEXT]: string;
}

export interface CompanyIacStopPayload {
  id: number;
  reason: string;
}

export interface CompanyIacTableFieldsLabel {
  label: string;
  className?: string;
}

export enum CompanyIacStartDealFieldNames {
  COMPANY_SHARE_PRICE = 'companySharePrice',
  DAXIA_SHARE_PRICE = 'daxiaSharePrice',
  FEE_RATE = 'feeRate',
  FEE_MINIMUM = 'feeMinimum',
}

export interface CompanyIacStartDealFormState {
  [CompanyIacStartDealFieldNames.COMPANY_SHARE_PRICE]: CompanyIacSharePrice[];
  [CompanyIacStartDealFieldNames.FEE_RATE]: string;
  [CompanyIacStartDealFieldNames.FEE_MINIMUM]: string;
  [CompanyIacStartDealFieldNames.DAXIA_SHARE_PRICE]: string;
}

export interface CompanyIacDeal {
  legalEntity: string;
  companyName: string;
  companyNumber: string;
  daxiaSharePrice: string;
  feeMinimum: string;
  feeRate: string;
  companySharePrice: CompanyIacSharePrice[];
}

export interface CreateDealParametersPayload {
  id: string;
  feeMinimum: number;
  feeRate: number;
}

export interface GetCompanyIacPayload {
  isAdmin: boolean;
  companyId: string;
  iacId?: string | undefined;
}

export interface CompaniesIacState extends MetaState {
  list: CompanyIac[];
  isLoading: boolean;
}

export interface CompaniesIacResponse {
  data: CompanyIac[];
  meta: MetaPage;
}
